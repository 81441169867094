<template>
    <div style="display: flex;justify-content: space-between;flex-direction: column;height: 100vh;">
        <div style="flex: 1">
            <div class="bgColor">
                <div class="headContent">
                    <img src="../assets/img/logo.png"/>
                    <p>支付成功</p>
                </div>
                <div class="content">
                    <div class="text1">支付金额</div>
                    <div class="price"><span>￥</span>{{amount}}</div>
                    <van-divider/>
                    <div class="itemContent">
                        <span class="text2">车牌号码</span>
                        <span>{{licencePlate}}</span>
                    </div>
                    <div class="itemContent">
                        <span class="text2">车场名称</span>
                        <span>{{parkingName}}</span>
                    </div>
                    <div class="itemContent">
                        <span class="text2">支付时间</span>
                        <span>{{payTime}}</span>
                    </div>
                </div>
            </div>
            <div class="div-btn">
                <van-button class="van-btn" block type="warning" @click="handleInvoice">
                    开发票
                </van-button>
                <van-button block type="default" @click="handleComplete">
                    完 成
                </van-button>
            </div>
        </div>
        <div class="advert-image">
            <div style="width: 100%;">
                <van-swipe :autoplay="3000">
                    <van-swipe-item v-for="(image, index) in adImg" :key="index">
                        <img class="box-img" v-lazy="image.url" @click="handleClick"/>
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
    </div>
</template>

<script>
    import {Button, Divider, Swipe, SwipeItem} from 'vant';
    import {getAdvertisement, getParking, payTempFinish} from "../api/interface";
    import Vue from 'vue';
    import {Lazyload} from 'vant';

    Vue.use(Lazyload);

    export default {
        name: "paymentCompletionPage",
        components: {
            [Divider.name]: Divider,
            [Button.name]: Button,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
        },
        data() {
            return {
                parkingName: "",
                licencePlate: "",
                amount: "",
                parkingId: "",
                payTime: "",
                adImg: [],
                targeType: "",
                target: "",
                getTime: ""
            }
        },
        created() {
            this.getCurrentTime();
            if (this.$route.query.order_no) {
                this.getTempOrderInfo();
            } else {
                this.amount = this.$route.query.amount;
                this.payTime = this.$route.query.payTime;
                this.parkingName = this.$route.query.parkingName;
                this.licencePlate = this.$route.query.licensePlate;
                getParking(this.$route.query.parkingId).then((res) => {
                    sessionStorage.setItem('projectId', res.data.data.projectId);
                    this.parkingName = res.data.data.parkingName;
                })
            }
        },
        methods: {
            getCurrentTime() {
                //获取当前时间并打印
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
                this.getTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            },
            getTempOrderInfo() {
                let param = {
                    type: 0,
                    orderNo: this.$route.query.order_no
                }
                payTempFinish(param).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        this.parkingName = res.data.data.parkingName;
                        this.amount = res.data.data.amount;
                        this.parkingId = res.data.data.parkingId;
                        this.licencePlate = res.data.data.licensePlate;
                        this.payTime = res.data.data.payTime;
                        this.advertisement();
                    }
                })
            },
            advertisement() {
                let param = {
                    parkingId: this.parkingId,
                    location: 1
                }
                getAdvertisement(param).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        this.adImg = res.data.data.materias;
                        this.targeType = res.data.data.targeType;
                        this.target = res.data.data.target;
                    }
                })
            },
            handleClick() {
                if (this.targeType == 0) {
                    window.location.href = this.target;
                }
            },
            handleInvoice() {
                this.$router.push({
                    path: '/invoice', query: {
                        order_no: this.$route.query.order_no,
                        amount: this.amount,
                        orderType: 'PARKING_TEMP',
                        parkingName: this.parkingName,
                        parkingId: this.parkingId
                    }
                });
            },
            handleComplete() {
                this.weixinClosePage();
            },
            weixinClosePage() {
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', this.weixin_ClosePage, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', this.weixin_ClosePage);
                        document.attachEvent('onWeixinJSBridgeReady', this.weixin_ClosePage);
                    }
                } else {
                    this.weixin_ClosePage();
                }
            },
            weixin_ClosePage() {
                WeixinJSBridge.call('closeWindow');
            }

        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#F9F9F9')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style scoped>
    .bgColor {
        background-color: white;
        padding: 10px;
    }

    .headContent {
        line-height: 10px;
        font-size: 18px;
        font-weight: bold;
    }

    .headContent img {
        width: 12%;
        height: 12%;
    }

    .content {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        margin: 0px 20px;
    }

    .text1 {
        display: flex;
        justify-content: left;
        color: #8a8a8a;
        font-weight: 200;
    }

    .price {
        color: #FD4A1B;
        font-size: 30px;
        font-weight: bold;
    }

    .price span {
        font-size: 20px;
    }

    .itemContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 40px;
        font-weight: 400;
    }

    .text2 {
        color: #8a8a8a;
        font-weight: 200;
    }

    /deep/ .van-divider {
        margin: 10px 0;
    }

    .div-btn {
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        margin: 20px 10px;
        font-size: 18px;
    }

    .div-btn .van-button {
        font-weight: 500;
        margin: 10px;
    }

    .div-btn .van-btn {
        background: linear-gradient(-30deg, #e85744, #e86f50);
    }

    .advert-image {
        flex: 1;
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
    }

    .box-img {
        max-width: 100%;
        max-height: 300px;
    }
</style>
